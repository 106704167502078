<template>
  <div class="bindMenusWrap">
    <div class="left">
      <el-input placeholder="请输入内容" v-model="roleName" clearable>
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <div
        :class="['role', selectedRoleId == r.id && 'activeRole']"
        v-for="r in rolesToShow"
        :key="r.id"
        @click="selectedRoleId = r.id"
      >
        {{ r.role_name }}
      </div>
    </div>
    <div class="right">
      <el-tree
        class="menuTree"
        :data="menusTreeData"
        :props="defaultProps"
        node-key="id"
        default-expand-all
        ref="tree"
        @node-click="nodeClickHandler"
        check-on-click-node
        :render-content="renderContent"
      >
      </el-tree>
    </div>
  </div>
</template>

<script>
import { deepFlattenRoutes } from "@/utils/menu.js";

export default {
  data() {
    return {
      allRolesListData: [],
      rolesToShow: [],
      menusTreeData: [],
      bindedMenusIds: [],
      selectedRoleId: "",
      roleName: "",
      defaultProps: {
        children: "children",
        label: "menu_name",
      },
    };
  },
  mounted() {
    this.getData({});
    this.getMenusTreeData();
  },
  watch: {
    roleName(v) {
      this.rolesToShow = this.allRolesListData.filter(
        (item) => item.role_name.indexOf(v) > -1
      );
    },
    selectedRoleId(v) {
      this.getRoleBindedMenus(v);
    },
    rolesToShow(v) {
      this.selectedRoleId = v[0]?.id;
    },
  },
  methods: {
    getRoleBindedMenus(v) {
      this.$request.getRoleBindedMenus({ role_id: v }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        const menus = deepFlattenRoutes([res.data || []]);
        const menusIds = menus.map((item) => {
          if (item.have_auth == "1") {
            return item.id;
          } else {
            return undefined;
          }
        });
        const ids = _.compact(menusIds);
        this.bindedMenusIds = ids || [];
      });
    },
    getMenusTreeData() {
      this.$request.getAllMenusTreeData({}).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.menusTreeData = [res.data];
      });
    },
    nodeClickHandler(node) {
      console.log(node, "node");
    },
    renderContent(h, { node }) {
      const nodeId = node?.data?.id;
      const isBinded = this.bindedMenusIds.includes(nodeId);
      return (
        <span class="nodeContent">
          <span>{node.label}</span>
          {isBinded ? (
            <span
              class="el-icon-unlock unlockMenu lock"
              onClick={(e) => {
                e.stopPropagation();
                this.authorizeMenuOperation(nodeId, true);
              }}
            />
          ) : (
            <span
              class="el-icon-lock lock"
              onClick={(e) => {
                e.stopPropagation();
                this.authorizeMenuOperation(nodeId);
              }}
            />
          )}
        </span>
      );
    },
    authorizeMenuOperation(menu_id, isDelete) {
      this.$request
        .menuaAthorizeOperation({
          role_id: this.selectedRoleId,
          menu_id,
          isDelete,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.getRoleBindedMenus(this.selectedRoleId);
        });
    },
    getData() {
      this.$request.queryRoles({ page_num: 1, page_size: 999 }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.allRolesListData = res?.data?.result || [];
        this.rolesToShow = res?.data?.result || [];
      });
    },
  },
};
</script>

<style lang='scss'>
.bindMenusWrap {
  display: flex;
  .left {
    width: 300px;
    min-height: 500px;
    background-color: #fff;
    box-shadow: 10px 0 10px 0 rgb(235 238 245 / 71%), 0 0.01rem 0 0 #ebeef5;
    margin-right: 10px;
    .el-input {
      width: 278px;
      margin: 11px;
    }
    .role {
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      cursor: pointer;
      color: #606266;
      font-size: 14px;
    }
    .activeRole {
      background-color: #f4f7fa;
    }
  }
  .right {
    flex: 1;
    padding: 24px;
    .menuTree {
      border-bottom: 1px solid #eaeaf4;
      .el-tree-node__content {
        height: 46px;
        border: 1px solid #eaeaf4;
        border-bottom: none;
      }
      .nodeContent {
        display: flex;
        font-size: 14px;
        width: 100%;
        justify-content: space-between;
        padding-right: 24px;
        .lock {
          font-size: 22px;
        }
        .unlockMenu {
          color: rgb(26, 145, 255) !important;
        }
      }
      .el-input--small {
        margin-bottom: 12px;
      }
    }
  }
}
</style>